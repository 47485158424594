<template>
  <div class="NewStudentDetail">
    <el-card class="box-card">
      <div class="header">
        <div class="name">{{ this.$route.query.className }} - 学习详情</div>
        <el-form ref="form" :model="form" class="form">
          <el-form-item prop="userId">
            <el-select v-model="form.userId" placeholder="请选择员工" clearable filterable>
              <el-option
                v-for="item in studentSelectList"
                :key="item.userId"
                :label="item.studentName"
                :value="item.userId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="btnWrap">
          <el-button type="primary" @click="search">查找</el-button>
          <el-button class="reset" @click="newsClick">重置</el-button>
        </div>
        <el-button style="margin-left:auto" @click="handleClassComment">
          导出答疑辅导
        </el-button>
      </div>
      <div class="content">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="课程学习统计" name="1">
            <Course
              ref="Course"
              :class-id="this.$route.query.classId"
              :active-index="activeName"
              :user-id="String(form.userId)"
            ></Course>
          </el-tab-pane>
          <el-tab-pane label="章节学习轨迹记录" name="2">
            <Chapter
              ref="Chapter"
              :class-id="this.$route.query.classId"
              :active-index="activeName"
              :user-id="String(form.userId)"
            ></Chapter>
          </el-tab-pane>
          <el-tab-pane label="打卡签到统计" name="3">
            <Clock
              ref="Clock"
              :class-id="this.$route.query.classId"
              :active-index="activeName"
              :user-id="String(form.userId)"
            ></Clock>
          </el-tab-pane>
          <el-tab-pane label="考试统计" name="4">
            <Exam
              ref="Exam"
              :class-id="this.$route.query.classId"
              :active-index="activeName"
              :user-id="String(form.userId)"
            ></Exam>
          </el-tab-pane>
          <el-tab-pane v-if="trainFlag" label="人脸识别记录" name="5">
            <FaceRecord
              ref="Exam"
              :class-id="this.$route.query.classId"
              :active-index="activeName"
              :user-id="String(form.userId)"
            >
            </FaceRecord>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-card>
  </div>
</template>

<script>
import Chapter from './components/Chapter.vue'
import Course from './components/Course.vue'
import Clock from './components/Clock.vue'
import Exam from './components/Exam.vue'
import FaceRecord from './components/FaceRecord.vue'
import { studentSelectList, manageClass, classCommentExport } from '@/api/class'
import to from 'await-to'
export default {
  components: {
    Chapter,
    Course,
    Clock,
    Exam,
    FaceRecord,
  },
  data() {
    return {
      studentSelectList: [],
      form: {
        userId: Number(this.$route.query.userId) || '',
      },
      activeName: '1',
      trainFlag: false,
    }
  },
  created() {
    this.getManageClass()
    this.studentSelectListData()
  },
  methods: {
    async getManageClass() {
      const { classId } = this.$route.query
      const [res, err] = await to(manageClass({ classId }))
      if (err) return this.$message.warning(err.msg)
      this.trainFlag = res.data.trainFlag
    },
    async studentSelectListData() {
      const { classId } = this.$route.query
      const [res, err] = await to(studentSelectList({ classId }))
      if (err) return this.$message.warning(err.msg)
      this.studentSelectList = res.data
    },
    handleClick(tab) {
      this.activeName = tab.name
    },
    search() {
      if (this.activeName == 1) {
        this.$refs.Course.courseStudyData()
      } else if (this.activeName == 2) {
        this.$refs.Chapter.sectionStudyhistoryData()
      } else if (this.activeName == 3) {
        this.$refs.Clock.clockStatisticsData()
      } else if (this.activeName == 4) {
        this.$refs.Exam.examStatisticsData()
      } else {
        this.$refs.Exam.identifyFaceCheckRecordData()
      }
    },
    newsClick() {
      this.form = Object.assign({}, { userId: '' })
      if (this.activeName == 1) {
        this.$refs.Course.resetForm()
      } else if (this.activeName == 2) {
        this.$refs.Chapter.resetForm()
      } else if (this.activeName == 3) {
        this.$refs.Clock.resetForm()
      } else if (this.activeName == 4) {
        this.$refs.Exam.resetForm()
      } else {
        this.$refs.Exam.resetForm()
      }
    },
    async handleClassComment() {
      const { classId } = this.$route.query
      const [res, err] = await to(classCommentExport({ classId }))
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '答疑辅导导出.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
  },
}
</script>

<style scoped lang="scss">
.NewStudentDetail {
  .header {
    ::v-deep .el-select .el-input {
      width: 235px !important;
    }
    ::v-deep .el-form-item {
      margin-bottom: 0 !important;
    }
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    .name {
      font-size: 18px;
      font-weight: 500;
    }
    .form {
      margin: 0 30px;
    }
    .reset {
      margin-left: 30px;
    }
  }
  .content {
    ::v-deep .el-tabs__item {
      font-size: 16px;
    }
  }
}
</style>
