<template>
  <div class="Clock">
    <div class="header">
      <div class="headerL">
        <el-form ref="form" :model="form" :inline="true" class="form">
          <el-form-item prop="courseId">
            <el-select v-model="form.examId" placeholder="请选择考试" clearable>
              <el-option
                v-for="item in examSelect"
                :key="item.examId"
                :label="item.examTitle"
                :value="item.examId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <el-button @click="exportRecord">导出记录</el-button>
    </div>
    <table-list
      :loading="loading"
      :data="list"
      :columns="columns(this)"
      :pager="pager"
      :options="{ selection: true }"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </table-list>
  </div>
</template>

<script>
// 表格列配置
const columns = () => [
  {
    prop: 'userName',
    minWidth: 120,
    label: '员工名称',
  },
  {
    prop: 'mobile',
    minWidth: 120,
    label: '手机号',
  },
  {
    prop: 'examTitle',
    label: '考试名称',
    minWidth: 200,
  },
  {
    prop: 'scoreTotal',
    label: '考试总分',
    minWidth: 120,
  },
  {
    prop: 'finalScore',
    label: '考试成绩',
    minWidth: 120,
  },
  {
    prop: 'platform',
    label: '使用设备',
    minWidth: 120,
  },
  {
    prop: 'examStartTime',
    label: '考试时间',
    minWidth: 160,
  },
  {
    prop: 'submitTime',
    label: '交卷时间',
    minWidth: 160,
  },
]
import TableList from '@/components/TableList'
import { examStatistics, examExport } from '@/api/class'
import { getExaminationList } from '@/api/examination'

import to from 'await-to'
export default {
  components: {
    TableList,
  },
  props: {
    classId: {
      type: String,
      default: '',
    },
    activeIndex: {
      type: String,
      default: '4',
    },
    userId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      columns,
      form: {},
      examSelect: [],
      list: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      select: [],
    }
  },
  watch: {
    activeIndex: {
      handler(val) {
        if (val == 4) {
          this.getExaminationListData()
          this.examStatisticsData()
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async getExaminationListData() {
      const [res, err] = await to(getExaminationList({ current: 1, size: 500 }))
      if (err) return this.$message.warning(err.msg)
      this.examSelect = res.data.list
    },
    async examStatisticsData() {
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(
        examStatistics({
          classId: this.classId,
          current,
          size,
          userId: this.userId,
          examId: this.form.examId,
          queryPlatform: 0,
        }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.list = res.data.records
      this.pager.total = res.data.total
    },
    resetForm() {
      this.form = Object.assign({}, {})
    },
    async exportRecord() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      const { current, size } = this.pager
      const [res, err] = await to(
        examExport({
          classId: this.classId,
          current,
          size,
          userId: this.userId,
          examId: this.form.examId,
          queryPlatform: 0,
          idList: this.select,
        }),
      )
      loading.close()
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '考试统计记录.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.examStatisticsData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.examStatisticsData()
    },
    handleSelectionChange(val) {
      val.map(v => this.select.push(v.id))
    },
  },
}
</script>

<style scoped lang="scss">
.Clock {
  .header {
    ::v-deep .el-select .el-input {
      width: 235px;
    }
    ::v-deep .el-form-item {
      margin-right: 40px;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    .headerL {
      display: flex;
      align-items: center;
    }
  }
}
</style>
