<template>
  <div class="Chapter">
    <div class="header">
      <div class="headerL">
        <el-form ref="form" :model="form" :inline="true" class="form">
          <el-form-item prop="courseId">
            <el-select
              v-model="form.courseId"
              placeholder="请选择课程"
              clearable
              @change="changeStatus"
            >
              <el-option
                v-for="item in courseSelect"
                :key="item.courseId"
                :label="item.courseName"
                :value="item.courseId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="sectionId">
            <el-select v-model="form.sectionId" placeholder="请选择章节" clearable>
              <el-option
                v-for="item in sectionSelect"
                :key="item.sectionId"
                :label="item.sectionName"
                :value="item.sectionId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-checkbox v-model="checked" @change="changBox">只看每节的最新进度</el-checkbox>
      </div>
      <el-button @click="exportRecord">导出记录</el-button>
    </div>
    <table-list
      :loading="loading"
      :data="list"
      :columns="columns(this)"
      :pager="pager"
      :options="{ selection: true }"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </table-list>
  </div>
</template>

<script>
// 表格列配置
const columns = _this => [
  {
    prop: 'studentName',
    label: '员工名称',
    minWidth: 120,
  },
  {
    prop: 'mobile',
    label: '手机号',
    minWidth: 120,
  },
  {
    prop: 'cardNo',
    label: '身份证号',
    minWidth: 200,
  },
  {
    prop: 'courseName',
    label: '课程名称',
    minWidth: 200,
  },
  {
    prop: 'sectionName',
    label: '章节名称',
    minWidth: 200,
  },
  {
    prop: 'beginTime',
    width: '150px',
    minWidth: 200,
    label: _this.checked ? '学习片段开始时间' : '开始学习',
  },
  {
    prop: 'endTime',
    width: '150px',
    minWidth: 200,
    label: _this.checked ? '学习片段结束时间' : '结束学习',
  },
  {
    prop: 'studyDuration',
    minWidth: 120,
    label: '学习时长',
  },
  {
    prop: 'studyDurationTotal',
    minWidth: 120,
    label: '累计观看时长',
  },
  {
    prop: 'sectionDuration',
    minWidth: 120,
    label: '视频总时长',
  },
  {
    prop: 'progress',
    minWidth: 120,
    label: '完成度',
    align: 'right',
    sortable: true,
  },
  {
    prop: 'studyCompletedTimeRange',
    label: '视频总完成时间段',
    minWidth: 180,
    render: (h, { row }) => {
      return h('div', [
        h('div', row.startTime !== null ? '始：' + row.startTime : ''),
        h('div', row.finishTime !== null ? '完：' + row.finishTime : ''),
      ])
    },
  },
  {
    prop: 'platform',
    label: '使用设备',
    minWidth: 120,
  },
  {
    prop: 'ip',
    label: 'IP',
    minWidth: 150,
  },
]
import TableList from '@/components/TableList'
import { courseSelect, sectionSelect, sectionStudyhistory, sectionExport } from '@/api/class'
import to from 'await-to'
export default {
  components: {
    TableList,
  },
  props: {
    classId: {
      type: String,
      default: '',
    },
    activeIndex: {
      type: String,
      default: '2',
    },
    userId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      courseSelect: [],
      sectionSelect: [],
      checked: true,
      loading: false,
      columns,
      form: {},
      list: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      select: [],
    }
  },
  watch: {
    activeIndex: {
      handler(val) {
        if (val == 2) {
          this.courseSelectData()
          this.sectionStudyhistoryData()
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async courseSelectData() {
      const [res, err] = await to(courseSelect({ classId: this.classId }))
      if (err) return this.$message.warning(err.msg)
      this.courseSelect = res.data
    },
    async sectionSelectData(e) {
      const [res, err] = await to(
        sectionSelect({ courseId: typeof e === 'string' ? this.classId : e }),
      )
      if (err) return this.$message.warning(err.msg)
      this.sectionSelect = res.data
    },
    async sectionStudyhistoryData() {
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(
        sectionStudyhistory({
          classId: this.classId,
          current,
          size,
          userId: this.userId,
          courseId: this.form.courseId,
          sectionId: this.form.sectionId,
          onlyLastRecordBySection: this.checked,
        }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.list = res.data.list
      this.pager.total = res.data.total
    },
    exportRecord() {
      if (this.select.length) {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        })
        // 导出表格的表头设置
        let allColumns = columns(this)
        var columnNames = []
        var columnValues = []
        for (var i = 0; i < allColumns.length; i++) {
          columnNames[i] = allColumns[i].label
          columnValues[i] = allColumns[i].prop
        }
        console.log(columnValues)
        require.ensure([], () => {
          const { export_json_to_excel } = require('@/vendor/Export2Excel')
          const tHeader = columnNames
          const filterVal = columnValues
          const list = this.select
          const data = this.formatJson(filterVal, list)
          export_json_to_excel(tHeader, data, '章节学习轨迹记录')
        })
        loading.close()
      } else {
        this.exportData()
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    async exportData() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      const [res, err] = await to(
        sectionExport({
          classId: this.classId,
          userId: this.userId,
          courseId: this.form.courseId,
          sectionId: this.form.sectionId,
          onlyLastRecordBySection: this.checked,
        }),
      )
      loading.close()
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '章节学习轨迹记录.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    changBox() {
      this.sectionStudyhistoryData()
    },
    changeStatus(e) {
      if (typeof e === 'string') this.form.sectionId = ''
      this.sectionSelectData(e)
    },
    resetForm() {
      this.form = Object.assign({}, {})
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.sectionStudyhistoryData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.sectionStudyhistoryData()
    },
    handleSelectionChange(val) {
      this.select = val
    },
  },
}
</script>

<style scoped lang="scss">
.Chapter {
  .header {
    ::v-deep .el-select .el-input {
      width: 235px !important;
    }
    ::v-deep .el-form-item {
      margin-right: 40px;
      margin-bottom: 0;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    .headerL {
      display: flex;
      align-items: center;
    }
  }
}
</style>
