<template>
  <div class="Chapter">
    <div class="header">
      <div class="headerL">
        <el-form ref="form" :model="form" :inline="true" class="form">
          <el-form-item prop="checkResult">
            <el-select v-model="form.checkResult" placeholder="请选择状态" clearable>
              <el-option label="全部" :value="-1"></el-option>
              <el-option label="未认证" :value="0"></el-option>
              <el-option label="成功" :value="1"></el-option>
              <el-option label="失败" :value="2"></el-option>
              <el-option label="超时失效" :value="3"></el-option>
            </el-select>
          </el-form-item>
		  
          <el-form-item prop="time">
             <el-date-picker
                       v-model="form.time"
                       type="daterange"
                       format="yyyy-MM-dd"
                       value-format="yyyy-MM-dd HH:mm:ss"
                       start-placeholder="开始日期"
                       end-placeholder="结束日期"
                       >
             </el-date-picker>
           </el-form-item>
           <el-form-item prop="checkScene">
             <el-select v-model="form.checkScene" placeholder="请选择认证场景" clearable>
               <el-option label="实名认证" :value="1"></el-option>
               <el-option label="班级学习第一次人脸" :value="2"></el-option>
               <el-option label="班级学习第二次人脸" :value="3"></el-option>
             </el-select>
           </el-form-item>
        </el-form>
      </div>
      <el-button @click="exportRecord">导出记录</el-button>
    </div>
    <table-list
      :loading="loading"
      :data="list"
      :columns="columns(this)"
      :pager="pager"
      :options="{ selection: true }"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </table-list>
  </div>
</template>

<script>
// 表格列配置
const columns = () => [
  {
    prop: 'userName',
    minWidth: 120,
    label: '员工名称',
  },
  {
    prop: 'phone',
    minWidth: 120,
    label: '手机号',
  },
  {
    prop: 'idCard',
    label: '身份证',
    minWidth: 200,
  },
  {
    prop: 'checkSceneStr',
    label: '认证场景',
    minWidth: 120,
  },
  {
    prop: 'checkResultTime',
    label: '人脸识别时间',
    minWidth: 120,
  },
  {
    prop: 'checkResultStr',
    label: '人脸识别状态',
    minWidth: 120,
  },
  {
    prop: 'checkResultMsg',
    label: '原因',
    minWidth: 160,
  }
]
import TableList from '@/components/TableList'
import {identifyFaceCheckRecord, identifyFaceCheckRecordExport } from '@/api/class'
import to from 'await-to'
// import { map } from 'core-js/fn/dict'
export default {
  components: {
    TableList,
  },
  props: {
    classId: {
      type: String,
      default: '',
    },
    activeIndex: {
      type: String,
      default: '5',
    },
    userId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      courseSelect: [],
      sectionSelect: [],
      loading: false,
      columns,
      form: {
        time: [],
        startTime:'',
        endTime:'',
      },
      list: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      select: [],
    }
  },
  watch: {
    activeIndex: {
      handler(val) {
        if (val == 5) {
          this.identifyFaceCheckRecordData()
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async identifyFaceCheckRecordData() {
      const { current, size } = this.pager
	  if(this.form.time && this.form.time.length != 0){
	    this.form.startTime = this.form.time[0];
	    this.form.endTime = this.form.time[1].split(' ')[0] + ' 23:59:59';
	  }
      this.loading = true
      const [res, err] = await to(
        identifyFaceCheckRecord({
			classId: this.classId,
			current,
			size,
			userId: this.userId,
			checkResult: this.form.checkResult,
			checkScene: this.form.checkScene,
			start:this.form.startTime,
			end:this.form.endTime,
        }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.list = res.data.list
      this.pager.total = res.data.total
    },
    exportRecord() {
      if (this.select.length) {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        })
        // 导出表格的表头设置
        let allColumns = columns(this)
        var columnNames = []
        var columnValues = []
        for (var i = 0; i < allColumns.length; i++) {
          columnNames[i] = allColumns[i].label
          columnValues[i] = allColumns[i].prop
        }
        console.log(columnValues)
        require.ensure([], () => {
          const { export_json_to_excel } = require('@/vendor/Export2Excel')
          const tHeader = columnNames
          const filterVal = columnValues
          const list = this.select
          const data = this.formatJson(filterVal, list)
          export_json_to_excel(tHeader, data, '人脸识别记录')
        })
        loading.close()
      } else {
        this.exportData()
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    async exportData() {
		console.log("接口");
	  
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
	  // val.map(v => this.select.push(v.id))
	  let idList = []
	  this.select.map(v => idList.push(v.id))
      const [res, err] = await to(
        identifyFaceCheckRecordExport({
          classId:this.classId,
          userId: this.userId,
          checkResult: this.form.checkResult,
          checkScene: this.form.checkScene,
          start:this.form.startTime,
          end:this.form.endTime,
          idList: idList,
        }),
      )
      loading.close()
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '人脸识别记录.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    resetForm() {
      this.form = Object.assign({}, {})
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.identifyFaceCheckRecordData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.identifyFaceCheckRecordData()
    },
    handleSelectionChange(val) {
      this.select = val
    },
  },
}
</script>

<style scoped lang="scss">
.Chapter {
  .header {
    ::v-deep .el-select .el-input {
      width: 235px !important;
    }
    ::v-deep .el-form-item {
      margin-right: 40px;
      margin-bottom: 0;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    .headerL {
      display: flex;
      align-items: center;
    }
  }
}
</style>
