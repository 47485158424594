<template>
  <div class="Clock">
    <div class="header">
      <div class="headerL">
        <el-form ref="form" :model="form" :inline="true" class="form">
          <el-form-item prop="courseId">
            <el-select v-model="form.courseId" placeholder="请选择课程" clearable>
              <el-option
                v-for="item in courseSelect"
                :key="item.courseId"
                :label="item.courseName"
                :value="item.courseId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <el-button @click="exportRecord">导出记录</el-button>
    </div>
    <table-list
      :loading="loading"
      :data="list"
      :columns="columns(this)"
      :pager="pager"
      :options="{ selection: true }"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </table-list>
  </div>
</template>

<script>
// 表格列配置
const columns = () => [
  {
    prop: 'userName',
    label: '员工名称',
    minWidth: 120,
  },
  {
    prop: 'mobile',
    label: '手机号',
    minWidth: 120,
  },
  {
    prop: 'courseName',
    label: '课程名称',
    minWidth: 200,
  },
  {
    prop: 'sectionName',
    label: '章节名称',
    minWidth: 200,
  },
  {
    prop: 'punchTime',
    label: '打卡签到时间',
    minWidth: 160,
  },
  {
    prop: 'platform',
    label: '使用设备',
    minWidth: 120,
  },
  {
    prop: 'ip',
    label: 'IP',
    minWidth: 150,
  },
]
import TableList from '@/components/TableList'
import { courseSelect, clockStatistics, clockExport } from '@/api/class'
import to from 'await-to'
export default {
  components: {
    TableList,
  },
  props: {
    classId: {
      type: String,
      default: '',
    },
    activeIndex: {
      type: String,
      default: '3',
    },
    userId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      columns,
      form: {},
      courseSelect: [],
      list: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      select: [],
    }
  },
  watch: {
    activeIndex: {
      handler(val) {
        if (val == 3) {
          this.courseSelectData()
          this.clockStatisticsData()
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async courseSelectData() {
      const [res, err] = await to(courseSelect({ classId: this.classId }))
      if (err) return this.$message.warning(err.msg)
      this.courseSelect = res.data
    },
    async clockStatisticsData() {
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(
        clockStatistics({
          classId: this.classId,
          current,
          size,
          userId: this.userId,
          courseId: this.form.courseId,
          queryPlatform: 0,
        }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.list = res.data.records
      this.pager.total = res.data.total
    },
    resetForm() {
      this.form = Object.assign({}, {})
    },
    async exportRecord() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      const { current, size } = this.pager
      const [res, err] = await to(
        clockExport({
          classId: this.classId,
          current,
          size,
          userId: this.userId,
          courseId: this.form.courseId,
          queryPlatform: 0,
          idList: this.select,
        }),
      )
      loading.close()
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '打卡签到统计记录.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.clockStatisticsData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.clockStatisticsData()
    },
    handleSelectionChange(val) {
      val.map(v => this.select.push(v.id))
    },
  },
}
</script>

<style scoped lang="scss">
.Clock {
  .header {
    ::v-deep .el-select .el-input {
      width: 235px;
    }
    ::v-deep .el-form-item {
      margin-right: 40px;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    .headerL {
      display: flex;
      align-items: center;
    }
  }
}
</style>
